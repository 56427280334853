import ElementUI from 'element-ui';
import Vue from 'vue';
import '../src/theme/index.css';
import App from './App.vue';
import router from './router/index';
import store from "./store";

import './utils/rem.js';

// import { monitorZoom } from "@/utils/monitorZoom.js";
// const m = monitorZoom();
// if (window.screen.width * window.devicePixelRatio >= 3840) {
//   document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
// } else {
//   document.body.style.zoom = 100 / Number(m);
// }


Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App),
}).$mount('#app')

