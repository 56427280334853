//1.导入VueRouter
import vuex from "@/store/index";
import Vue from "vue";
import VueRouter from 'vue-router';
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    //  mode:'history', 
    //4.配置路由的path和组件
    routes :[
        {
          path: "/",
          name:'login',
          component: () => import("../view/login/login.vue"),
        },
        {
          path: "/list",
          name:'home',
          component: () => import("../view/home/home.vue"),
          children:[
            { path: '/', name: 'inde', component: ()=> import("../components/page/inde.vue") }, 
            { path: '/create', name: 'inde', component: ()=> import("../components/page/create.vue") }, 
          ]
        },
      ]
})
//5.导入路由实例
export default router
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  const tokenStr = vuex.state.token
  // 1.如果访问的是登录页面（无需权限），直接放行
  if(!tokenStr){
    if(to.path === '/viewText' || from.path == '/examination' || from.path == '/information'){
      return next('/')
    }
  }
  if (to.path === '/login' || to.path === '/') return next()
  // 2.如果访问的是有登录权限的页面，先要获取token
  
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) {
    return next('/')
  }
  next()
})

